import React, {Component} from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import './event.css'
import MultiImageSlider from "../components/MultiImageSlider";
import {Grid, Paper} from "@material-ui/core";
import SEO from "../components/SEO";
import Banner from "../components/Banner";

class EventTemplate extends Component {
    render() {
        const event = this.props.data.wordpressWpEvent;
        return (
            <Layout id='event'>
                <SEO title={event.yoast_meta.yoast_wpseo_title}
                     description={event.yoast_meta.yoast_wpseo_metadesc}
                />
                <div className='event-wrapper'>
                    {
                        event.acf.event.image &&
                        <Banner
                            title={event.acf.event.title}
                            description={event.acf.event.description}
                            image={event.acf.event.image}
                        />
                    }

                    <Grid container type="flex" justify="center" className='content-wrapper'>
                        <Grid item xl={6} lg={8} md={12} xs={12} className='content-centered'>
                            <h2 className='title-big'>{event.acf.event.title}</h2>

                            <Paper elevation={1} style={{marginBottom: '1em', padding: '2em'}}>
                                {event.acf.event.level && <div><strong>Level:</strong> {event.acf.event.level}</div>}
                                {event.acf.event.instructor && <div><strong>Instructor: </strong> {event.acf.event.instructor}</div>}
                                <div><strong>Datum: </strong> {event.acf.event.startdate} {event.acf.event.enddate && <span> - {event.acf.event.enddate}</span>}</div>
                                <div><strong>Preis: </strong> {event.acf.event.price}</div>
                            </Paper>

                            <div dangerouslySetInnerHTML={{__html: event.acf.event.longdescription}}></div>
                        </Grid>
                    </Grid>

                    {
                        event.acf.impressions && <MultiImageSlider nodes={event.acf.impressions}/>
                    }
                </div>
            </Layout>
        )
    }
}

EventTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default EventTemplate;

export const pageQuery = graphql`
    query($id: String!) {
        site {
            siteMetadata {
                title
            }
        }
        wordpressWpEvent(id: { eq: $id }) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                impressions {
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                event {
                    title
                    longdescription
                    startdate
                    enddate
                    level
                    instructor
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                    price
                    category
                    type
                }

            }

        }

    }
`;