import React from 'react'
import Slider from "react-slick";
import "./multi-image-slider.css";
import Image from "../components/Image";


export default class MultiImageSlider extends React.Component {
    constructor() {
        super();
        this.state = {data: []};
    }

    render() {
        const settings = {
            infinite: true,
            variableWidth: true,
            lazyLoad: true,
            adaptiveHeight: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        variableWidth: false,
                        adaptiveHeight: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        variableWidth: false,
                        adaptiveHeight: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: false,
                        adaptiveHeight: false,
                    }
                }]
        };
        return (
            <div  className='multislider' style={{padding: '2em', textAlign: 'center'}}>
                <Slider {...settings} >
                    {
                        this.props.nodes && this.props.nodes.map((node, index) => {
                            return (
                                <div key={`image_${index}`} style={{maxHeight: '15em', overflow: 'hidden'}}>
                                    {node.localFile && <Image className="img" image={node}/>}
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        );
    }
}
